import styled from 'styled-components'
import React, { createContext, useCallback, useContext } from 'react'
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { FooterBottom, FooterBottomProps } from './footerBottom'
import { Icon } from '../icon/icon'
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { theme } from '../../theme'
import { FooterConnect } from './footerConnect'
import Image from 'next/image'
import jump from 'jump.js'

export type FooterWrapperProps = {
    //
  }
export type FooterTopProps = {
    //
  }
export type FooterFirstRowProps = {
    //
  }
export type FooterSecondRowProps = {
    //
  }


export type FooterProps = 
    | (
      {
        top?: never
        bottom?: never
        firstRow?: never
        secondRow?: never
      } & FooterWrapperProps
    )
    | (
      {
        top: true
        bottom?: never
        firstRow?: never
        secondRow?: never
      } & FooterTopProps
    )
    | (
      {
        top?: never
        bottom: true
        firstRow?: never
        secondRow?: never
      } & FooterBottomProps
    )
    | (
      {
        top?: never
        bottom?: never
        firstRow: true
        secondRow?: never
      } & FooterFirstRowProps
    )
    | (
      {
        top?: never
        bottom?: never
        firstRow?: never
        secondRow: true
      } & FooterSecondRowProps
    )

type breakpointContextType = {
  desktop: boolean
  tablet: boolean
  mobile: boolean
}

export const FooterWrap = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(38)};
  }
`

export const FooterEdge = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 ${props => props.theme.typography.pxToRem(100)}, 100% 0%, 100% 100%, 0% 100%);
    -webkit-backface-visibility: hidden;
    background-color: ${props => props.theme.palette.primary.dark};
  }
`

export const FooterTop = styled(Grid)`
  color: ${props => props.theme.palette.common.white};
  height: 100%;
  padding-bottom: ${props => props.theme.typography.pxToRem(65)};

  ${props => props.theme.breakpoints.up('md')} {
    padding-left: ${props => props.theme.typography.pxToRem(80)};
    padding-right: ${props => props.theme.typography.pxToRem(80)};
  }
`

export const FooterHeading = styled(Typography)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => props.theme.typography.pxToRem(22)};
`

const FirstRow = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(152)};
`

const SmallStyledLogo = styled(Image)`
  height: ${props => props.theme.typography.pxToRem(82)};
  width: ${props => props.theme.typography.pxToRem(150)};
  margin-bottom: ${props => props.theme.typography.pxToRem(38)};
`

const SmallLogoRow = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(39)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`

const UpArrow = styled(Icon)`
  background: none;
  font-size: ${props => props.theme.typography.pxToRem(32)};
  color: ${props => props.theme.palette.common.white};
  position: absolute;
  width: ${props => props.theme.typography.pxToRem(18)};
  height: ${props => props.theme.typography.pxToRem(18)};
  bottom: ${props => props.theme.typography.pxToRem(-49)};
  right: ${props => props.theme.typography.pxToRem(-9)};
  transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(30)};
    height: ${props => props.theme.typography.pxToRem(30)};
    bottom: ${props => props.theme.typography.pxToRem(-65)};
    right: ${props => props.theme.typography.pxToRem(-13)};
  }
`

const UpButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  border-left: ${props => props.theme.typography.pxToRem(36)} solid transparent;
  border-right: ${props => props.theme.typography.pxToRem(36)} solid transparent;
  border-bottom: ${props => props.theme.typography.pxToRem(62)} solid ${props => props.theme.palette.primary.main};
  position: absolute;
  bottom: ${props => props.theme.typography.pxToRem(-21)};

  ${props => props.theme.breakpoints.up('sm')} {
    right: 13%;
    border-left: ${props => props.theme.typography.pxToRem(49)} solid transparent;
    border-right: ${props => props.theme.typography.pxToRem(49)} solid transparent;
    border-bottom: ${props => props.theme.typography.pxToRem(82)} solid ${props => props.theme.palette.primary.main};
    bottom: ${props => props.theme.typography.pxToRem(-27)};
  }

  &:hover {
    border-bottom: ${props => props.theme.typography.pxToRem(62)} solid ${props => props.theme.palette.secondary.main};

    ${props => props.theme.breakpoints.up('sm')} {
      border-bottom: ${props => props.theme.typography.pxToRem(82)} solid ${props => props.theme.palette.secondary.main};
    }
    > ${UpArrow} {
      transform: translateY(${props => props.theme.typography.pxToRem(-5)});
    }
  }
`

const AccordionContainer = styled(Grid)`
  & > div:nth-child(1) > div:nth-child(1) {
    border-top: none;
  }

  & > div:nth-child(2) > div:nth-child(2) {
    border-bottom: solid ${props => props.theme.typography.pxToRem(1)} rgba(255, 255, 255, 0.45);
  }

  ${props => props.theme.breakpoints.up('lg')} {
    & > div:nth-child(2) > div:nth-child(2) {
      border-bottom: unset;
    }
  }
`

const SecondRow = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(37)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: 0;
  }
`

const CUSALogo = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(96)};
`

export const breakpointContext = createContext<breakpointContextType>({
  desktop: false,
  tablet: false,
  mobile: false,
})


export const Footer: React.FC<FooterProps>  = ({ top, bottom, firstRow, secondRow, children, ...props }) => {
  if (top) {
    return (
      <FooterTopWrapper {...props}>{children}</FooterTopWrapper>
    )
  }

  if (bottom) {
    return (
      <FooterBottomWrapper {...props}/>
    )
  }

  if (firstRow) {
    return (
      <FirstRowWrapper {...props}>{children}</FirstRowWrapper>
    )
  }

  if (secondRow) {
    return (
      <SecondRowWrapper {...props}>{children}</SecondRowWrapper>
    )
  }

  return (
    <FooterWrapper {...props}>{children}</FooterWrapper>
  )
}

const FooterWrapper: React.FC<FooterWrapperProps> = ({ children, ...props }) => {
  const desktop = useMediaQuery(theme.breakpoints.up('lg'))
  const tablet = useMediaQuery(theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <breakpointContext.Provider value={{ 
      desktop: desktop, tablet: tablet, mobile: mobile,
    }}>
      <FooterWrap {...props}> 
        {children}
      </FooterWrap> 
    </breakpointContext.Provider>
  )
}

const FooterTopWrapper: React.FC<FooterTopProps> = ({ children, ...props }) => {
  const scrollToTop = useCallback(() => {
    jump('#__next', {
      duration: 800,
      a11y: true,
    })
  }, [])

  return (
    <FooterEdge>
      <Container maxWidth="xl">
        <FooterTop
          {...props} 
          container
          lg={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </FooterTop>
      </Container>
      <UpButton {...props} onClick={scrollToTop} href={'#main'}>
        <UpArrow icon={faChevronUp} />
      </UpButton>
    </FooterEdge>
  )
}

const FooterBottomWrapper: React.FC<FooterBottomProps> = ({ acknowledgement, copyrightText, leftLinks, rightLinks, children, ...props }) => {
  return (
    <Container maxWidth="xl">
      <FooterBottom {...props} acknowledgement={acknowledgement} copyrightText={copyrightText} leftLinks={leftLinks} rightLinks={rightLinks} />    
    </Container>
  )
}

const FirstRowWrapper: React.FC<FooterFirstRowProps> = ({ children, ...props }) => {
  const { desktop } = useContext(breakpointContext)

  return(
    <Grid
      item
      md={10}
    >
      <FirstRow
        container
        direction="row"
        justifyContent={desktop ? 'flex-end' : 'center'}
        alignItems="center"
        {...props}
      >
        {!desktop && (
          <Grid
            item
            sm={6}
          >
            <SmallLogoRow
              container
              direction="row"
              justifyContent="center"
            >
              <SmallStyledLogo
                src="/images/CUSA.svg"
                alt=""
                height={72}
                width={150}
              />
            </SmallLogoRow>
          </Grid>
        )}

        <Grid
          item
          lg={6}
          md={12}
        >
          {children}
        </Grid>
      </FirstRow>
    </Grid>
  )
}

const SecondRowWrapper: React.FC<FooterSecondRowProps> = ({ children }) => {
  const { desktop } = useContext(breakpointContext)

  return(
    
    <SecondRow
      item
      lg={10}
    >
      <Grid
        container
        direction="row-reverse"
        justifyContent={desktop ? 'space-between' : 'center'} 
      >
        <AccordionContainer
          item
          lg={6}
          md={12}
        >
          {children}
        </AccordionContainer>
        <Grid
          item
          lg={6}
          md={12}
        >
          <Grid
            container
            direction="column"
          >
            {desktop && (
              <CUSALogo
                item
              >
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >


                  <Image
                    src="/images/CUSA.svg"
                    alt=""
                    width={262}
                    height={125}
                  />

                </Grid>
              </CUSALogo>
            )}
            <FooterConnect />
          </Grid>
        </Grid>
      </Grid>
    </SecondRow>
  )
}